import { DEFAULT_EVENT_MMDDYY } from 'constants/dates';
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import { notification } from 'antd';
import {
  CalcDuration,
  convertDateDriverHomeTimeZone,
  convertPixelsToTime,
  convertPixelsToTimeDateFormat,
  convertTimeToMoment,
  convertTimeToPixels,
  getStatusEventCode,
  passwordGenerator,
} from 'helpers/utils';

import moment, { Moment } from 'moment';
import 'moment-duration-format';
import { DriverResponseType } from 'services/drivers';
import { editLogFormType } from 'services/logs';
import { CSVGraphStatusType, EditLogRequest, Violation } from 'types/hos';
import { TimeZone } from 'types/common';
import { queryClient } from 'hooks';

import { useHistory } from 'react-router-dom';
import { GET_HOS_DRIVER_CSV } from 'hooks/keys';
import { useGetCoordinates, useGetLatLong, useSaveEditLog } from 'hooks/unitHooks';
import { HosValidation } from 'schemas/hos';
import { ReactComponent as PowerUp } from 'resources/icons/graph_icons/on.svg';
import { ReactComponent as PowerDown } from 'resources/icons/graph_icons/off.svg';
import signatureImage from 'resources/images/signature_light.png';
import EditLogForm from '../EditLogForm/EditLogForm';
import Slider from './GraphSlider';
import OfflineWarningModal from '../OfflineWarningModal/OfflineWarningModal';

import './Graph.less';

export type shiftType = {
  eventDate: string;
  eventTime: string;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const Graph = ({
  logDataGraph,
  date,
  violations,
  driverDetail,
  isEditRequestAvailbe,
  setTotalTime,
  selectedLog,
  setSelectedLog,
  setHighlightedLog,
  actionType,
  setActionType,
  resetActionType,
  selectedRange,
  setSelectedRange,
}: {
  logDataGraph: any;
  date?: string;
  violations: Violation[];
  driverDetail: DriverResponseType | undefined;
  isEditRequestAvailbe?: boolean;
  setTotalTime?: React.Dispatch<React.SetStateAction<number>>;
  selectedLog?: any;
  setSelectedLog?: any;
  setHighlightedLog?: any;
  actionType?: number;
  setActionType?: React.Dispatch<React.SetStateAction<number>>;
  resetActionType?: () => void;
  selectedRange?: any;
  setSelectedRange?: any;
}) => {
  const histroy = useHistory();

  const convertedDate = moment(date, 'YYYY-MM-DD').format(DEFAULT_EVENT_MMDDYY);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let logStorageData: any;
  if (!sessionStorage.getItem(`selectdLogDatail`)) {
    histroy.push('/logs');
  } else {
    logStorageData = JSON.parse(sessionStorage.getItem(`selectdLogDatail`) || '');
  }
  const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: logStorageData?.driverTimeZone };
  const currentDateInZone = convertDateDriverHomeTimeZone(new Date(), tzCode);
  const currentDate = currentDateInZone.format(DEFAULT_EVENT_MMDDYY);
  const rightLimit =
    convertedDate === currentDate ? convertTimeToPixels(currentDateInZone.format('HHmmss')) - 900 : 86399;
  const shift_start_time: shiftType[] = logDataGraph?.meta?.deviceCalculations?.SHIFT_START_DATE || [];
  const reset_shift_time = logDataGraph?.meta?.deviceCalculations?.CYCLE_START_DATE;
  const tenantId = driverDetail?.id || logStorageData?.tenantId;
  const driverId = driverDetail?.id || logStorageData?.driverId;

  const saveEditLog = useSaveEditLog();
  const getLocation = useGetLatLong();
  const getCoordinates = useGetCoordinates();

  const [timeFrom, setTimeFrom] = useState<Moment | null>(convertTimeToMoment(convertedDate, selectedRange?.from));
  const [timeTo, setTimeTo] = useState<Moment | null>(convertTimeToMoment(convertedDate, selectedRange?.to));
  const [sliderChange, setSliderChange] = useState<boolean>(false);
  const [showOfflineWarning, setShowOfflineWarningModal] = useState<boolean>(false);
  const [highlightedTimeoutId, setHighlightedTimeoutId] = useState<number>(0);
  const [formInitialValues, setFormInitialValues] = useState<editLogFormType>({
    status: '',
    lat: '',
    lng: '',
    odometer: '',
    engHours: '',
    shippingDocument: '',
    trailer: '',
    location: '',
    notes: '',
  });
  const [tempSelectedLog, setTempSelectedLog] = useState<CSVGraphStatusType | null>();
  const [tempSelectedRange, setTempSelectedRange] = useState<object>({ from: null, to: null });
  const offDutyRecordsHeaderFill: CSVGraphStatusType[] = [];
  const logData: CSVGraphStatusType[] = logDataGraph?.csv?.eldEventListForDriversRecordOfDutyStatus
    ?.filter((item: CSVGraphStatusType) => item?.eventRecordStatus === '1' && item?.eventDate === convertedDate)
    ?.sort((a: CSVGraphStatusType, b: CSVGraphStatusType) => Number(a.eventTime) - Number(b.eventTime)) || [
    {
      eventSequenceIdNumber: '1',
      eventRecordStatus: '1',
      eventRecordOrigin: '1',
      eventType: '1',
      eventCode: getStatusEventCode(logStorageData?.lastActivityStatus, logStorageData?.currentDate, convertedDate),
      eventDate: convertedDate,
      eventTime: '000000',
      accumulatedVehicleMiles: 0,
      accumulatedEngineHours: 0,
      eventLatitude: '0.0',
      eventLongitude: '0.0',
      distanceSinceLastValidCoordinates: 0,
      correspondingCmvOrderNumber: '1',
      userOrderNumberForRecordOriginator: '1',
      malfunctionIndicatorStatusForEld: '0',
      dataDiagnosticEventIndicatorForDriver: '1',
      eventDataCheckValue: 'F2',
      lineDataCheckValue: '67',
      address: logStorageData?.meta?.lastActivity?.address || '',
    },
  ];
  const intermediateLocationsRecords: CSVGraphStatusType[] = logData?.filter(item => item?.eventType === '2');
  const missingintermediateRecords: CSVGraphStatusType[] = [];
  const logPowerUpDownEvents: CSVGraphStatusType[] = logDataGraph?.csv?.cmvEnginePowerUpShutDownActivity || [];
  const certifyEvents: CSVGraphStatusType[] =
    logDataGraph?.csv?.eldEventListForDriverCertificationOfOwnRecords?.filter(
      (item: CSVGraphStatusType) =>
        item?.certificateType === '4' && item?.eventDate === moment(date, 'YYYY-MM-DD').format('MMDDYY'),
    ) || [];

  const offDutyRecords: CSVGraphStatusType[] = logData?.filter(
    (item: CSVGraphStatusType) => item?.eventType === '1' || item?.eventType === '3',
  );

  const logDataGraphExtention = {
    offDuty: {
      totalOffDutyTime: 0,
      isOffDuty: false,
    },
    sleeperBerth: {
      totalSleeperBerthTime: 0,
      isSleeperBerth: false,
    },
    onDriving: {
      totalDrivingTime: 0,
      isDriving: false,
    },
    onDuty: {
      totalOnDutyTime: 0,
      isOnDuty: false,
    },
  };

  offDutyRecords?.map((item: CSVGraphStatusType, index: number) => {
    const start = moment(item?.eventTime, 'HH:mm:ss');
    const end = moment(
      offDutyRecords[index + 1]?.eventTime
        ? offDutyRecords[index + 1]?.eventTime
        : [convertedDate === currentDate ? currentDateInZone.format('HHmmss') : '235959'],
      'HH:mm:ss',
    );
    const showData = moment.duration(end.diff(start)).asSeconds();
    const durationTime = moment.utc(end.diff(start)).format('HH:mm:ss');
    if (item?.eventType === '1' && item?.eventCode === '1') {
      logDataGraphExtention.offDuty.totalOffDutyTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.offDuty.isOffDuty = true;
      }
    } else if (item?.eventType === '1' && item?.eventCode === '2') {
      logDataGraphExtention.sleeperBerth.totalSleeperBerthTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.sleeperBerth.isSleeperBerth = true;
      }
    } else if (item?.eventType === '1' && item?.eventCode === '3') {
      logDataGraphExtention.onDriving.totalDrivingTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.onDriving.isDriving = true;
      }

      // calculate missing entries
      let startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss').add(1, 'hours');
      if (
        item?.eventTime === '000000' &&
        item?.eventType === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventType &&
        item?.eventCode === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventCode
      ) {
        const lastLogTime = logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventTime;
        const minutes = parseInt(lastLogTime.substring(2, 4), 10);
        const seconds = parseInt(lastLogTime.substring(4, 6), 10);
        startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss')
          .add(minutes, 'minutes')
          .add(seconds, 'seconds');
      }

      const endTime = moment(`${item?.eventDate} ${end?.format('HHmmss')}`, 'MMDDYY HHmmss');
      // const startTime = start.add(1, 'hours').format('HHmmss');
      // const endTime = end.format('HHmmss');
      if (startTime.isBefore(endTime)) {
        for (let i = startTime; i < endTime; i = moment(i, 'MMDDYY HHmmss').add(1, 'hours')) {
          const firstTime = moment(i, 'MMDDYY HHmmss').add(300, 'seconds');
          const secondTime = moment(i, 'MMDDYY HHmmss').subtract(300, 'seconds');
          const exist = intermediateLocationsRecords.find((currentItem: CSVGraphStatusType) =>
            moment(`${currentItem?.eventDate} ${currentItem?.eventTime}`, 'MMDDYY HHmmss').isBetween(
              secondTime,
              firstTime,
              null,
              '[]',
            ),
          );
          if (!exist) {
            const object = {
              accumulatedEngineHours: 0,
              accumulatedVehicleMiles: 0,
              address: '1.14 mi S of Mattoo Bhaieke, Punjab',
              correspondingCmvOrderNumber: '1',
              dataDiagnosticEventIndicatorForDriver: '0',
              distanceSinceLastValidCoordinates: 0,
              eventCode: '1',
              eventDataCheckValue: '8D',
              eventDate: item.eventDate,
              eventEndTime: '',
              eventLatitude: '32',
              eventLongitude: '74',
              eventRecordOrigin: '3',
              eventRecordStatus: '1',
              eventSequenceIdNumber: i.format('HHmmss'),
              eventTime: i.format('HHmmss'),
              eventType: '2',
              lineDataCheckValue: '5B',
              malfunctionIndicatorStatusForEld: '0',
              notes: '',
              state: 'Wisconsin',
              totalEngineHoursDutyStatus: '13',
              totalVehicleMilesDutyStatus: '12',
              userOrderNumberForRecordOriginator: '1',
              intermediateType: '1',
            };
            missingintermediateRecords.push(object!);
          }
        }
      }
    } else if (item?.eventType === '1' && item?.eventCode === '4') {
      logDataGraphExtention.onDuty.totalOnDutyTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.onDuty.isOnDuty = true;
      }
    } else if (item?.eventType === '3' && item?.eventCode === '1') {
      logDataGraphExtention.offDuty.totalOffDutyTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.offDuty.isOffDuty = true;
      }
      // calculate missing entries
      let startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss').add(1, 'hours');
      if (
        item?.eventTime === '000000' &&
        item?.eventType === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventType &&
        item?.eventCode === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventCode
      ) {
        const lastLogTime = logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventTime;
        const minutes = parseInt(lastLogTime.substring(2, 4), 10);
        const seconds = parseInt(lastLogTime.substring(4, 6), 10);
        startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss')
          .add(minutes, 'minutes')
          .add(seconds, 'seconds');
      }
      const endTime = moment(`${item?.eventDate} ${end?.format('HHmmss')}`, 'MMDDYY HHmmss');
      // const startTime = start.add(1, 'hours').format('HHmmss');
      // const endTime = end.format('HHmmss');
      if (startTime.isBefore(endTime)) {
        for (let i = startTime; i < endTime; i = moment(i, 'MMDDYY HHmmss').add(1, 'hours')) {
          const firstTime = moment(i, 'MMDDYY HHmmss').add(300, 'seconds');
          const secondTime = moment(i, 'MMDDYY HHmmss').subtract(300, 'seconds');
          const exist = intermediateLocationsRecords.find((currentItem: CSVGraphStatusType) =>
            moment(`${currentItem?.eventDate} ${currentItem?.eventTime}`, 'MMDDYY HHmmss').isBetween(
              secondTime,
              firstTime,
              null,
              '[]',
            ),
          );
          if (!exist) {
            const object = {
              accumulatedEngineHours: 0,
              accumulatedVehicleMiles: 0,
              address: '1.14 mi S of Mattoo Bhaieke, Punjab',
              correspondingCmvOrderNumber: '1',
              dataDiagnosticEventIndicatorForDriver: '0',
              distanceSinceLastValidCoordinates: 0,
              eventCode: '1',
              eventDataCheckValue: '8D',
              eventDate: item.eventDate,
              eventEndTime: '',
              eventLatitude: '32',
              eventLongitude: '74',
              eventRecordOrigin: '3',
              eventRecordStatus: '1',
              eventSequenceIdNumber: i.format('HHmmss'),
              eventTime: i.format('HHmmss'),
              eventType: '2',
              lineDataCheckValue: '5B',
              malfunctionIndicatorStatusForEld: '0',
              notes: '',
              state: 'Wisconsin',
              totalEngineHoursDutyStatus: '13',
              totalVehicleMilesDutyStatus: '12',
              userOrderNumberForRecordOriginator: '1',
              intermediateType: '2',
            };
            missingintermediateRecords.push(object!);
          }
        }
      }
    } else if (item?.eventType === '3' && item?.eventCode === '2') {
      logDataGraphExtention.onDuty.totalOnDutyTime += showData;
      if (offDutyRecords?.length === index + 1) {
        logDataGraphExtention.onDuty.isOnDuty = true;
      }
      // calculate missing entries
      let startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss').add(1, 'hours');
      if (
        item?.eventTime === '000000' &&
        item?.eventType === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventType &&
        item?.eventCode === logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventCode
      ) {
        const lastLogTime = logDataGraph?.meta?.deviceCalculations?.lastLogTime?.eventTime;
        const minutes = parseInt(lastLogTime.substring(2, 4), 10);
        const seconds = parseInt(lastLogTime.substring(4, 6), 10);
        startTime = moment(`${item?.eventDate} ${item?.eventTime}`, 'MMDDYY HHmmss')
          .add(minutes, 'minutes')
          .add(seconds, 'seconds');
      }
      const endTime = moment(`${item?.eventDate} ${end?.format('HHmmss')}`, 'MMDDYY HHmmss');
      // const startTime = start.add(1, 'hours').format('HHmmss');
      // const endTime = end.format('HHmmss');
      if (startTime.isBefore(endTime)) {
        for (let i = startTime; i < endTime; i = moment(i, 'MMDDYY HHmmss').add(1, 'hours')) {
          const firstTime = moment(i, 'MMDDYY HHmmss').add(300, 'seconds');
          const secondTime = moment(i, 'MMDDYY HHmmss').subtract(300, 'seconds');
          const exist = intermediateLocationsRecords.find((currentItem: CSVGraphStatusType) =>
            moment(`${currentItem?.eventDate} ${currentItem?.eventTime}`, 'MMDDYY HHmmss').isBetween(
              secondTime,
              firstTime,
              null,
              '[]',
            ),
          );
          if (!exist) {
            const object = {
              accumulatedEngineHours: 0,
              accumulatedVehicleMiles: 0,
              address: '1.14 mi S of Mattoo Bhaieke, Punjab',
              correspondingCmvOrderNumber: '1',
              dataDiagnosticEventIndicatorForDriver: '0',
              distanceSinceLastValidCoordinates: 0,
              eventCode: '1',
              eventDataCheckValue: '8D',
              eventDate: item.eventDate,
              eventEndTime: '',
              eventLatitude: '32',
              eventLongitude: '74',
              eventRecordOrigin: '3',
              eventRecordStatus: '1',
              eventSequenceIdNumber: i.format('HHmmss'),
              eventTime: i.format('HHmmss'),
              eventType: '2',
              lineDataCheckValue: '5B',
              malfunctionIndicatorStatusForEld: '0',
              notes: '',
              state: 'Wisconsin',
              totalEngineHoursDutyStatus: '13',
              totalVehicleMilesDutyStatus: '12',
              userOrderNumberForRecordOriginator: '1',
              intermediateType: '3',
            };
            missingintermediateRecords.push(object!);
          }
        }
      }
    }
    offDutyRecordsHeaderFill?.push({ ...item, eventEndTime: end?.format('HHmmss'), durationTime });
    return logDataGraphExtention;
  });

  const slightlyBiggerLineHeight = 1250;
  const verticalDividerSpaceBetween = 3600;
  const hrDividerSpaceBetween = 300;
  const sectionHeight = 2500;
  let hrVerticalSpacePosition = 0;
  let currentVerticalSpacePosition = 0;

  const GettopLabel = () => {
    const spacing = 3570;
    let counter = 0;
    const topLabels = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      'N',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      'M',
    ];

    return (
      <text x="0" y="-1000" fontWeight="bold">
        <tspan x={0} fontSize={800} fill="#586973">
          M
        </tspan>
        {topLabels.map(label => {
          counter += 1;
          return (
            <tspan x={counter * spacing} fontSize={800} key={`${label}_${counter}`} fill="#586973">
              {label}
            </tspan>
          );
        })}
      </text>
    );
  };

  const getVerticalSpace = () => {
    currentVerticalSpacePosition += verticalDividerSpaceBetween;
    return currentVerticalSpacePosition;
  };

  const GetVerticalLine = () => {
    getVerticalSpace();
    return (
      <line
        key={`line_row_${passwordGenerator(5)}`}
        y2="10000"
        y1="0"
        x2={currentVerticalSpacePosition}
        x1={currentVerticalSpacePosition}
        stroke="#C9CCCE"
        strokeWidth="50"
        fill="none"
      />
    );
  };

  const getSmallVerticalLineSpace = () => {
    hrVerticalSpacePosition += hrDividerSpaceBetween;
    return hrVerticalSpacePosition;
  };

  const resetSmallLinesCounter = () => {
    hrVerticalSpacePosition = 0;
  };

  const getSmallVerticalLine = (usSlightlyBigger = false, row = 1) => {
    const getStartingPointForThisLine = () => (usSlightlyBigger ? sectionHeight * row : sectionHeight * row);

    const getEndingPointForThisLine = () =>
      getStartingPointForThisLine() - (usSlightlyBigger ? slightlyBiggerLineHeight : 625);

    getSmallVerticalLineSpace();
    return (
      <line
        key={`line_row_${passwordGenerator(5)}`}
        y2={getEndingPointForThisLine().toString()}
        y1={getStartingPointForThisLine()}
        x2={hrVerticalSpacePosition}
        x1={hrVerticalSpacePosition}
        stroke="#C9CCCE"
        strokeWidth="50"
      />
    );
  };

  const getLnStartingPoint = (eventType: string, status: string) => {
    let sectionLineStartingPoint = 1;
    if (eventType === '3' && status === '1') {
      sectionLineStartingPoint = 1;
    } else if (eventType === '3' && status === '2') {
      sectionLineStartingPoint = 4;
    } else {
      sectionLineStartingPoint = Number(status);
    }

    return sectionHeight * sectionLineStartingPoint;
  };

  const transformDataForGraphLines = (
    graphData: CSVGraphStatusType,
    index: number,
    completeData: CSVGraphStatusType[],
  ) => {
    const validGraphData = graphData;
    const x1 = convertTimeToPixels(validGraphData?.eventTime);
    const y1 = getLnStartingPoint(validGraphData?.eventType, validGraphData?.eventCode) - slightlyBiggerLineHeight;
    const y2 = getLnStartingPoint(validGraphData?.eventType, validGraphData?.eventCode) - slightlyBiggerLineHeight;

    let vy1 = 0;
    let x2 = 0;
    // check the order of next status and decide the starting point of vertical line that connects with another status
    if (completeData?.length > index + 1) {
      /**
       * After edit log change, we need to check "updated" property first against each log
       * get next log entry and check if that contains "updated" object, get its status otherwise status would be on top
       */
      const nextStatusCode = completeData[index + 1]?.eventCode;
      const nextStatusType = completeData[index + 1]?.eventType;
      const currentStatusCode = validGraphData?.eventCode;
      const currentStatusType = validGraphData?.eventType;
      x2 = convertTimeToPixels(completeData[index + 1]?.eventTime);
      if (!(nextStatusCode === currentStatusCode && nextStatusType === currentStatusType)) {
        vy1 =
          getLnStartingPoint(completeData[index + 1]?.eventType, completeData[index + 1]?.eventCode) -
          slightlyBiggerLineHeight;
      }
    } else {
      x2 =
        convertedDate === currentDate
          ? convertTimeToPixels(currentDateInZone.format('HHmmss'))
          : convertTimeToPixels('235959');
    }

    const result = {
      horizLn: {
        x1,
        x2,
        y1,
        y2,
      },
      eventType: validGraphData?.eventType,
    };
    // no further status, so do not draw a vertical line afterwards
    if (vy1 > 0) {
      Object.assign(result, {
        vertLn: {
          x1: x2,
          x2,
          y1: vy1,
          y2,
        },
      });
    }

    return result;
  };

  const graphLinesData = offDutyRecords?.map(transformDataForGraphLines);

  let visibleTimeFrom = false;
  let visibleTimeTo = false;
  if (
    selectedLog &&
    selectedLog?.eventSequenceIdNumber === offDutyRecords[0]?.eventSequenceIdNumber &&
    selectedLog?.eventSequenceIdNumber === offDutyRecords[offDutyRecords.length - 1]?.eventSequenceIdNumber
  ) {
    visibleTimeFrom = true;
    visibleTimeTo = true;
  } else if (selectedLog && selectedLog?.eventSequenceIdNumber === offDutyRecords[0]?.eventSequenceIdNumber) {
    visibleTimeFrom = true;
  } else if (
    selectedLog &&
    selectedLog?.eventSequenceIdNumber === offDutyRecords[offDutyRecords.length - 1]?.eventSequenceIdNumber
  ) {
    visibleTimeTo = true;
  }

  if (setTotalTime) {
    setTotalTime(
      logDataGraphExtention?.onDriving?.totalDrivingTime + logDataGraphExtention?.onDuty?.totalOnDutyTime || 0,
    );
  }

  const calculateLimits = () => {
    const rightLimitSeconds =
      convertedDate === currentDate ? convertTimeToPixels(currentDateInZone.format('HHmmss')) : 86400;
    let startTime = '000000';
    let endTime = '001000';
    const midPointSeconds = rightLimitSeconds / 2;
    if (midPointSeconds <= 3600) {
      const halfMidPoint = midPointSeconds / 2;
      startTime = convertPixelsToTime(midPointSeconds - halfMidPoint);
      endTime = convertPixelsToTime(midPointSeconds + halfMidPoint);
    } else {
      startTime = convertPixelsToTime(midPointSeconds - 1800);
      endTime = convertPixelsToTime(midPointSeconds + 1800);
    }

    return [startTime, endTime];
  };

  const getEventTypeFromEventCode = (eventCode: string) => {
    let eventType = '3';
    if (eventCode !== '5' && eventCode !== '6') {
      eventType = '1';
    }
    return eventType;
  };

  const getEventCodeFromStatus = (status: string) => {
    let eventCode = status;
    if (status === '5') {
      eventCode = '1';
    } else if (status === '6') {
      eventCode = '2';
    }
    return eventCode;
  };

  const currentEventCode = () => {
    let eventCode = '';
    if (selectedLog?.eventType === '3' && selectedLog?.eventCode === '1') {
      eventCode = '5';
    } else if (selectedLog?.eventType === '3' && selectedLog?.eventCode === '2') {
      eventCode = '6';
    } else if (selectedLog) {
      eventCode = selectedLog?.eventCode;
    }
    return eventCode;
  };

  const onChangeTimeFrom = (time: Moment | null) => {
    const timeFromInZone = moment(timeFrom!).set({
      hours: time?.hours(),
      minutes: time?.minutes(),
      seconds: time?.seconds(),
    });

    const fromMinutes = timeFromInZone ? timeFromInZone.hour() * 60 + timeFromInZone.minute() : undefined;
    const toMinutes = timeTo ? timeTo.hour() * 60 + timeTo.minute() : undefined;

    if (
      // timeFromInZone?.format('HHmmss') < timeTo!.format('HHmmss')
      fromMinutes !== undefined &&
      toMinutes !== undefined &&
      fromMinutes < toMinutes
    ) {
      setTimeFrom(timeFromInZone);
      setSliderChange(true);
      setSelectedRange({ ...selectedRange, from: timeFromInZone?.format('HHmmss') });
    }
  };

  const onChangeTimeTo = (time: Moment | null) => {
    const timeToInZone = moment(timeTo!).set({
      hours: time?.hours(),
      minutes: time?.minutes(),
      seconds: time?.seconds(),
    });

    const fromMinutes = timeFrom ? timeFrom.hour() * 60 + timeFrom.minute() : undefined;
    const timeToMinutes = timeToInZone ? timeToInZone.hour() * 60 + timeToInZone.minute() : undefined;
    const rightLimitMinutes = convertPixelsToTime(rightLimit)
      ? convertPixelsToTimeDateFormat(rightLimit).hour() * 60 + convertPixelsToTimeDateFormat(rightLimit).minute()
      : undefined;
    if (
      // timeToInZone?.format('HHmmss') > timeFrom!.format('HHmmss') &&
      // timeToInZone?.format('HHmmss') <= convertPixelsToTime(rightLimit)
      timeToMinutes !== undefined &&
      fromMinutes !== undefined &&
      rightLimitMinutes !== undefined &&
      timeToMinutes > fromMinutes &&
      timeToMinutes <= rightLimitMinutes
    ) {
      setTimeTo(timeToInZone);
      setSliderChange(true);
      setSelectedRange({ ...selectedRange, to: timeToInZone?.format('HHmmss') });
    }
  };

  const handleSaveLogForm = (values: editLogFormType, formikHelpers: FormikHelpers<editLogFormType>) => {
    let finalActionType = actionType;
    if (
      selectedLog &&
      (selectedLog?.eventEndTime < timeFrom!.format('HHmmss') || timeTo!.format('HHmmss') < selectedLog?.eventTime)
    ) {
      finalActionType = 3;
    }
    const logs: CSVGraphStatusType = {
      ...selectedLog,
      startTime: timeFrom?.format('HHmmss'),
      endTime: timeTo?.format('HHmmss'),
      eventCode: getEventCodeFromStatus(values?.status),
      eventType: getEventTypeFromEventCode(values?.status),
      notes: values?.notes,
      address: values?.location,
      eventLatitude: values?.lat.replace(/\s/g, ''),
      eventLongitude: values?.lng.replace(/\s/g, ''),
      totalVehicleMilesDutyStatus: values?.odometer.replace(/\s/g, ''),
      totalEngineHoursDutyStatus: values?.engHours.replace(/\s/g, ''),
      shippingDocumentNumber: values?.shippingDocument,
      trailerNumber: values?.trailer,
      date,
      state: values?.state,
    };
    const finalData: EditLogRequest = {
      driverId,
      tenantId,
      actionType: finalActionType,
      logs,
    };
    saveEditLog
      .mutateAsync(finalData)
      .then((response: any) => {
        notification.success({ message: response?.message });
        formikHelpers.setSubmitting(false);
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch((error: any) => {
        notification.error({ message: error?.message });
      });
  };

  const getLocationFunction = (latProp: string, longProp: string, formProps: FormikHelpers<any>) => {
    const lat = latProp.replace(/\s/g, '');
    const long = longProp.replace(/\s/g, '');
    getLocation
      .mutateAsync({ lat, long })
      .then(response => {
        formProps.setFieldValue('location', response.data.address);
        formProps.setFieldValue('state', response.data.state);
      })
      .catch(error => {
        notification.error({ message: error?.message });
      });
  };
  const getCoordinatesFunction = (location: string, formProps: FormikHelpers<any>) => {
    getCoordinates
      .mutateAsync({ location })
      .then(response => {
        if (response.data.lat && response.data.lng) {
          formProps.setFieldValue('lat', `${response.data.lat}`);
          formProps.setFieldValue('lng', `${response.data.lng}`);
        } else {
          formProps.setFieldError('location', response.message);
        }
      })
      .catch(error => {
        notification.error({ message: error?.message });
      });
  };
  const rightLabelTime = (seconds: number) => {
    const format = moment.utc(seconds * 1000).format('HH:mm:ss');
    if (format === '23:59:59') {
      return '24:00';
    }
    return moment.utc(seconds * 1000).format('HH:mm');
  };
  const handleContinueEdit = () => {
    setSelectedLog(tempSelectedLog);
    setSelectedRange(tempSelectedRange);
    setShowOfflineWarningModal(false);
    setActionType?.(1);
    setTempSelectedLog(null);
    setSliderChange(false);
  };
  const handleCloseOfflineWarning = () => {
    setTempSelectedRange({ from: null, to: null });
    setTempSelectedLog(null);
    setShowOfflineWarningModal(false);
  };
  useEffect(() => {
    setTimeFrom(convertTimeToMoment(convertedDate, selectedRange?.from));
    setTimeTo(convertTimeToMoment(convertedDate, selectedRange?.to));
  }, [selectedRange]);

  useEffect(() => {
    // const logs = logData.filter((item: CSVGraphStatusType) => item?.eventType === '1' || item?.eventType === '3');
    if (actionType === 2) {
      const [from, to] = calculateLimits();
      setSelectedRange({ from, to });
      setSelectedLog(null);
      setFormInitialValues({
        status: '',
        lat: '',
        lng: '',
        odometer: '',
        engHours: '',
        shippingDocument: '',
        trailer: '',
        location: '',
        notes: '',
      });
    } else if (actionType === 3 && offDutyRecords?.length > 0) {
      setSelectedRange({
        from: offDutyRecordsHeaderFill[0].eventTime!,
        to: offDutyRecordsHeaderFill[0]?.eventEndTime!,
      });
      setSelectedLog(offDutyRecordsHeaderFill[0]);
      if (setActionType) {
        setActionType(1);
      }
    }
  }, [actionType]);

  const handleMouseEnter = (log: CSVGraphStatusType) => {
    const timeoutId = setTimeout(() => {
      setHighlightedLog(log);
    }, 1000);
    // @ts-ignore
    setHighlightedTimeoutId(timeoutId);
  };
  const handleMouseLeave = () => {
    clearTimeout(highlightedTimeoutId);
    setHighlightedLog(null);
  };

  useEffect(() => {
    if (selectedLog && actionType !== 2) {
      setFormInitialValues({
        status: currentEventCode(),
        lat: selectedLog?.eventLatitude || '',
        lng: selectedLog?.eventLongitude || '',
        odometer: selectedLog?.totalVehicleMilesDutyStatus || '',
        engHours: selectedLog?.totalEngineHoursDutyStatus || '',
        shippingDocument: selectedLog?.shippingDocumentNumber || '',
        trailer: selectedLog?.trailerNumber || '',
        location: selectedLog?.address || '',
        notes: selectedLog?.notes || '',
      });
    }
  }, [selectedLog]);
  return (
    <div className="graphSection">
      <svg width="100%" height="100%" viewBox="-5000 -5000 96000 20000">
        <g id="timeLine" fontFamily="roboto">
          {GettopLabel()}
        </g>
        <g id="leftLabels">
          {!logDataGraphExtention.offDuty.isOffDuty ? (
            <text x="-2400" y="1600" fontSize="800" fill="#586973" fontWeight="bold">
              OFF
            </text>
          ) : (
            <foreignObject width="2000" height="1000" x="-2800" y="700">
              <div className="DutyTag offDutyTag">OFF</div>
            </foreignObject>
          )}
          {!logDataGraphExtention.sleeperBerth.isSleeperBerth ? (
            <text x="-1900" y="4100" fontSize="800" fill="#586973" fontWeight="bold">
              SB
            </text>
          ) : (
            <foreignObject width="2000" height="1000" x="-2800" y="3200">
              <div className="DutyTag sleeperBerthTag">SB</div>
            </foreignObject>
          )}
          {!logDataGraphExtention.onDriving.isDriving ? (
            <text x="-1600" y="6600" fontSize="800" fill="#586973" fontWeight="bold">
              D
            </text>
          ) : (
            <foreignObject width="2000" height="1000" x="-2800" y="5700">
              <div className="DutyTag onDrivingTag">D</div>
            </foreignObject>
          )}
          {!logDataGraphExtention.onDuty.isOnDuty ? (
            <text x="-2100" y="9100" fontSize="800" fill="#586973" fontWeight="bold">
              ON
            </text>
          ) : (
            <foreignObject width="2000" height="1000" x="-2800" y="8200">
              <div className="DutyTag onDutyTag">ON</div>
            </foreignObject>
          )}
        </g>
        <g id="rightLabels">
          <text x="87000" y="1600" fontSize="800" fill="#586973" fontWeight="bold">
            {rightLabelTime(logDataGraphExtention.offDuty.totalOffDutyTime)}
          </text>
          <text x="87000" y="4100" fontSize="800" fill="#586973" fontWeight="bold">
            {rightLabelTime(logDataGraphExtention.sleeperBerth.totalSleeperBerthTime)}
          </text>
          <text x="87000" y="6600" fontSize="800" fill="#586973" fontWeight="bold">
            {rightLabelTime(logDataGraphExtention.onDriving.totalDrivingTime)}
          </text>
          <text x="87000" y="9100" fontSize="800" fill="#586973" fontWeight="bold">
            {rightLabelTime(logDataGraphExtention.onDuty.totalOnDutyTime)}
          </text>
        </g>
        <g id="bars">
          <rect key="react1" height="2500" width="86400" y="0" x="0" stroke="#C9CCCE" strokeWidth="50" fill="#fff" />
          <rect
            key="react2"
            height="2500"
            width="86400"
            y="2500"
            x="0"
            stroke="#C9CCCE"
            strokeWidth="50"
            fill="#f0f0f5"
          />
          <rect key="react3" height="2500" width="86400" y="5000" x="0" stroke="#C9CCCE" strokeWidth="50" fill="#fff" />
          <rect
            key="react4"
            height="2500"
            width="86400"
            y="7500"
            x="0"
            stroke="#C9CCCE"
            strokeWidth="50"
            fill="#f0f0f5"
          />
        </g>
        <g id="verticalLine">
          {Array(23)
            .fill('')
            .map(() => GetVerticalLine())}
        </g>
        <g id="smallLines">
          {Array(4)
            .fill('')
            .map((item, index) => {
              const lines = Array(24)
                .fill('')
                .map(() => {
                  const smallLines = Array(11)
                    .fill('')
                    .map((smLn, smLnInd) => getSmallVerticalLine(smLnInd === 5, index + 1));

                  getSmallVerticalLineSpace();

                  return smallLines;
                });
              resetSmallLinesCounter();
              return lines;
            })}
        </g>
        <g id="workingBlueLine">
          {graphLinesData &&
            graphLinesData?.map((data: any, index: number) => (
              <React.Fragment key={`id_${index + 1}`}>
                <rect
                  className="highlight-rect"
                  x={Math.min(data?.horizLn.x1, data?.horizLn.x2)}
                  y={0}
                  width={data?.horizLn.x2 - data?.horizLn.x1}
                  height="10000"
                  fill="transparent"
                  onClick={() => {
                    if (!isEditRequestAvailbe && setActionType && driverDetail?.enableElog) {
                      if (actionType !== 1) {
                        setTempSelectedRange({
                          from: offDutyRecordsHeaderFill[index].eventTime!,
                          to: offDutyRecordsHeaderFill[index].eventEndTime!,
                        });
                        setTempSelectedLog({
                          ...offDutyRecordsHeaderFill[index]!,
                          isLast: index === graphLinesData.length - 1,
                        });
                        setShowOfflineWarningModal(true);
                      } else {
                        setSelectedRange({
                          from: offDutyRecordsHeaderFill[index].eventTime!,
                          to: offDutyRecordsHeaderFill[index].eventEndTime!,
                        });
                        setSelectedLog({
                          ...offDutyRecordsHeaderFill[index]!,
                          isLast: index === graphLinesData.length - 1,
                        });
                        setActionType(1);
                      }
                    }
                  }}
                  onMouseEnter={() => handleMouseEnter(offDutyRecordsHeaderFill[index]!)}
                  onMouseLeave={() => handleMouseLeave()}
                />
                {/* Duration Box */}
                {!selectedLog && actionType === 0 && (
                  <g className="duration-boxes">
                    {/* Left Duration Box */}
                    <g>
                      <rect
                        x={data?.horizLn.x1 - 4000}
                        y={-500}
                        stroke="#acacac"
                        strokeWidth="50"
                        rx="150"
                        ry="150"
                        className="duration"
                      />
                      <text
                        x={data?.horizLn.x1 - 1000}
                        y={300}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className="duration-text">
                        {moment(convertPixelsToTime(data?.horizLn.x1), 'HHmmss').format('hh:mm:ss A')}
                      </text>
                    </g>

                    {/* Right Duration Box */}
                    <g>
                      <rect
                        x={data?.horizLn.x2 - 2000}
                        y={-500}
                        stroke="#acacac"
                        strokeWidth="50"
                        rx="150"
                        ry="150"
                        className="duration"
                      />
                      <text
                        x={data?.horizLn.x2 + 1000}
                        y={300}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className="duration-text">
                        {moment(convertPixelsToTime(data?.horizLn.x2), 'HHmmss').format('hh:mm:ss A')}
                      </text>
                    </g>

                    {/* Middle Duration Box */}
                    <g>
                      <rect
                        x={
                          Math.min(data?.horizLn.x1, data?.horizLn.x2) +
                          Math.abs(data?.horizLn.x2 - data?.horizLn.x1) / 2 -
                          3000
                        }
                        y={10500}
                        stroke="#acacac"
                        strokeWidth="50"
                        rx="150"
                        ry="150"
                        className="duration"
                      />
                      <text
                        x={
                          Math.min(data?.horizLn.x1, data?.horizLn.x2) +
                          Math.abs(data?.horizLn.x2 - data?.horizLn.x1) / 2
                        }
                        y={11300}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className="duration-text">
                        {CalcDuration(data?.horizLn.x1, data?.horizLn.x2)}
                      </text>
                    </g>
                  </g>
                )}
                <line
                  x1={data?.horizLn.x1}
                  x2={data?.horizLn.x2}
                  y1={data?.horizLn.y1}
                  y2={data?.horizLn.y2}
                  stroke="#2B5F8C"
                  fill="#2B5F8C"
                  strokeWidth={125}
                  strokeDasharray={data?.eventType === '3' ? '200,100' : ''}
                />
                <line
                  x1={data?.vertLn?.x1}
                  x2={data?.vertLn?.x2}
                  y1={data?.vertLn?.y1}
                  y2={data?.vertLn?.y2}
                  stroke="#2B5F8C"
                  fill="#2B5F8C"
                  strokeWidth={125}
                />
              </React.Fragment>
            ))}
        </g>
        <g id="workingRedLine">
          {violations &&
            convertedDate === violations[0]?.startedAt?.eventDate &&
            violations.length > 0 &&
            violations?.map((item: Violation, index: number) => {
              let endedAtEventTime: string;
              if (item.endedAt.eventTime) {
                endedAtEventTime = item.endedAt.eventTime;
              } else if (convertedDate === currentDate) {
                endedAtEventTime = currentDateInZone.format('HHmmss');
              } else {
                endedAtEventTime = '235959';
              }

              if (item?.startedAt?.eventDate !== convertedDate) {
                return null;
              }

              const x1 = convertTimeToPixels(item?.startedAt?.eventTime);
              const x2 = convertTimeToPixels(endedAtEventTime);
              const y1 = getLnStartingPoint('1', '3') - slightlyBiggerLineHeight;
              const y2 = getLnStartingPoint('1', '3') - slightlyBiggerLineHeight;
              return (
                <line
                  key={`id_${index + 1}`}
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  stroke="#f2163e"
                  fill="#2B5F8C"
                  strokeWidth={125}
                />
              );
            })}
        </g>
        <g id="shift_start_highlight">
          {shift_start_time?.length > 0 &&
            shift_start_time?.map((item: any, index: number) => {
              const id = `key${index + 1}`;
              if (item?.eventDate !== convertedDate) return null;
              return (
                <g id="shift_start" key={id}>
                  <foreignObject x={convertTimeToPixels(item?.eventTime) - 900} y={-750} width="1800" height="1500">
                    <div className="shift_start_box fourteen_shift_start">
                      <span>14</span>
                    </div>
                  </foreignObject>
                  <line
                    x1={convertTimeToPixels(item?.eventTime)}
                    x2={convertTimeToPixels(item?.eventTime)}
                    y1={750}
                    y2={10000}
                    stroke="#52c41a"
                    fill="#52c41a"
                    strokeWidth={160}
                  />
                </g>
              );
            })}
          {/* {shift_start_time && shift_start_time?.eventDate === convertedDate && (
            
          )} */}
          {reset_shift_time && reset_shift_time?.eventDate === convertedDate && (
            <g id="reset_shift">
              <foreignObject
                x={convertTimeToPixels(reset_shift_time?.eventTime) - 900}
                y={-750}
                width="1800"
                height="1500">
                <div className="shift_start_box shift_reset">
                  <span>34</span>
                </div>
              </foreignObject>
              <line
                x1={convertTimeToPixels(reset_shift_time?.eventTime)}
                x2={convertTimeToPixels(reset_shift_time?.eventTime)}
                y1={750}
                y2={10000}
                stroke="#2b5f8c"
                fill="#2b5f8c"
                strokeWidth={160}
              />
            </g>
          )}

          {/* <foreignObject x={75000 - 900} y={-750} width="1800" height="1500">
            <div className="shift_start_box split_sb">
              <span>SSB</span>
            </div>
          </foreignObject>
          <line x1={75000} x2={75000} y1={750} y2={10000} stroke="#ff9933" fill="#ff9933" strokeWidth={160} /> */}
        </g>
        <g id="power_icons">
          {logPowerUpDownEvents &&
            logPowerUpDownEvents?.map((item: CSVGraphStatusType, index: number) => {
              const id = `id_${index}`;
              const x = convertTimeToPixels(item?.eventTime);
              const tooltip_x = x - 3500;
              return (
                <g key={id}>
                  {item?.eventCode === '1' || item?.eventCode === '2' ? (
                    <PowerUp
                      x={x - 500}
                      y={-2850}
                      className="graph_icon"
                      onMouseEnter={() => handleMouseEnter(logPowerUpDownEvents[index])}
                      onMouseLeave={() => handleMouseLeave()}
                    />
                  ) : (
                    <PowerDown
                      x={x - 500}
                      y={-2850}
                      className="graph_icon"
                      onMouseEnter={() => handleMouseEnter(logPowerUpDownEvents[index])}
                      onMouseLeave={() => handleMouseLeave()}
                    />
                  )}
                  <rect
                    x={tooltip_x}
                    y="-4850"
                    stroke="#acacac"
                    strokeWidth="50"
                    rx="150"
                    ry="150"
                    className="tooltip"
                  />
                  <text x={x} y="-3850" className="tooltip-text-upper">
                    {moment(item?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A')}
                  </text>
                </g>
              );
            })}
        </g>
        <g id="certify">
          {certifyEvents &&
            certifyEvents.map((item: CSVGraphStatusType, index: number) => {
              const id = `id_${index}`;
              const x = convertTimeToPixels(item?.eventTime);
              const tooltip_x = x - 3500;
              return (
                <g key={id}>
                  <image
                    x={x - 500}
                    y={-2850}
                    width="1000"
                    height="1000"
                    className="graph_icon"
                    xlinkHref={signatureImage}
                    onMouseEnter={() => handleMouseEnter(certifyEvents[index])}
                    onMouseLeave={() => handleMouseLeave()}
                  />
                  <rect
                    x={tooltip_x}
                    y="-4850"
                    stroke="#acacac"
                    strokeWidth="50"
                    rx="150"
                    ry="150"
                    className="tooltip"
                  />
                  <text x={x} y="-3850" className="tooltip-text-upper">
                    {moment(item?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A')}
                  </text>
                </g>
              );
            })}
        </g>
        <g id="missing_intermediate_circles">
          {missingintermediateRecords &&
            missingintermediateRecords?.map((item: CSVGraphStatusType, index: number) => {
              const id = `id_${index}`;
              const x = convertTimeToPixels(item?.eventTime);
              const tooltip_x = x - 3500;
              let y = 6250;
              if (item.intermediateType === '2') {
                y = 1250;
              } else if (item.intermediateType === '3') {
                y = 8750;
              }
              // if (item?.missing) intermediateColor = 'red';
              return (
                <g key={id}>
                  <circle
                    cx={x}
                    cy={y}
                    r="180"
                    fill="red"
                    className="graph_icon"
                    onMouseEnter={e => {
                      e.currentTarget.setAttribute('r', '370');
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.setAttribute('r', '185');
                    }}
                  />
                  <rect
                    x={tooltip_x}
                    y={y - 2500}
                    stroke="#acacac"
                    strokeWidth="50"
                    rx="150"
                    ry="150"
                    className="tooltip"
                  />
                  <text x={x} y={y - 1500} className="tooltip-text-upper">
                    {moment(item?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A')}
                  </text>
                </g>
              );
            })}
        </g>
        <g id="intermediate_circles">
          {intermediateLocationsRecords &&
            intermediateLocationsRecords?.map((item: CSVGraphStatusType, index: number) => {
              const id = `id_${index}`;
              const x = convertTimeToPixels(item?.eventTime);
              const tooltip_x = x - 3500;
              let y = 6250;
              let intermediateColor = '#52c41a';
              if (item.intermediateType === '2') {
                y = 1250;
                intermediateColor = '#586973';
              } else if (item.intermediateType === '3') {
                y = 8750;
                intermediateColor = '#2b5f8c';
              } else if (item.intermediateType === '4') {
                y = 1250;
                intermediateColor = 'red';
              } else if (item.intermediateType === '5') {
                y = 3750;
                intermediateColor = 'red';
              } else if (item.intermediateType === '6') {
                y = 8750;
                intermediateColor = 'red';
              }
              return (
                <g key={id}>
                  <circle
                    cx={x}
                    cy={y}
                    r="180"
                    fill={intermediateColor}
                    className="graph_icon"
                    onMouseEnter={e => {
                      e.currentTarget.setAttribute('r', '370');
                      handleMouseEnter(intermediateLocationsRecords[index]!);
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.setAttribute('r', '185');
                      handleMouseLeave();
                    }}
                  />
                  <rect
                    x={tooltip_x}
                    y={y - 2500}
                    stroke="#acacac"
                    strokeWidth="50"
                    rx="150"
                    ry="150"
                    className="tooltip"
                  />
                  <text x={x} y={y - 1500} className="tooltip-text-upper">
                    {moment(item?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A')}
                  </text>
                </g>
              );
            })}
        </g>

        {actionType && actionType !== 0 && offDutyRecords?.length > 0 && (
          <Slider
            range={selectedRange}
            selectedLog={selectedLog}
            currentDateInZone={currentDateInZone}
            setSelectedRange={setSelectedRange}
            rightLimit={rightLimit}
            visibleTimeFrom={visibleTimeFrom}
            visibleTimeTo={visibleTimeTo}
            setSliderChange={setSliderChange}
          />
        )}
      </svg>
      {actionType && actionType !== 0 && offDutyRecords?.length > 0 ? (
        <div className="EditLogs EditLogStatus">
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={HosValidation}
            onSubmit={handleSaveLogForm}>
            {formProps => (
              <Form size="large" layout="vertical" initialValues={formInitialValues}>
                <EditLogForm
                  resetActionType={resetActionType}
                  timeFrom={timeFrom}
                  onChangeTimeFrom={onChangeTimeFrom}
                  timeTo={timeTo}
                  onChangeTimeTo={onChangeTimeTo}
                  visibleTimeFrom={visibleTimeFrom}
                  visibleTimeTo={visibleTimeTo}
                  getCoordinatesFunction={getCoordinatesFunction}
                  getLocationFunction={getLocationFunction}
                  formProps={formProps}
                  sliderChange={sliderChange}
                  actionType={actionType}
                  rightLimit={convertPixelsToTime(rightLimit)}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : null}

      <OfflineWarningModal
        openModal={showOfflineWarning}
        setShowOfflineWarningModal={handleCloseOfflineWarning}
        handleContinueEdit={handleContinueEdit}
      />
    </div>
  );
};

export default Graph;
