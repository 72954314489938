import { ViolationsPTIType, ViolationsType } from 'constants/config';
import { Badge, Col, Row } from 'antd';
import React from 'react';
import { svgIcons } from 'resources/icons';
import { PtiViolation, Violation } from 'types/hos';
 
const ViolationsGraph = ({ violations, pti }: { violations: Violation[]; pti: PtiViolation[] }) => {
  const logViolations: string[] = [];
  const ptiViolations: string[] = [];
  const violtaionOccure: string[] = violations?.map(item => item.type);
  const ptiVioltaionOccur = pti?.map(item => item.type);

  let breakViolationCounter = 0;
  let driveViolationCounter = 0;
  let shiftViolationCounter = 0;
  let cycleViolationCounter = 0;

  let ptiCounter = 0;
  let inSufficientViolationCounter = 0;

  violtaionOccure?.forEach(item => {
    if (item === ViolationsType.Break30Mint && breakViolationCounter === 0) {
      logViolations.push(item);
      breakViolationCounter += 1;
    } else if (item === ViolationsType.Break30Mint && breakViolationCounter > 0) {
      breakViolationCounter += 1;
    }
    if (item === ViolationsType.driving11Hours && driveViolationCounter === 0) {
      logViolations.push(item);
      driveViolationCounter += 1;
    } else if (item === ViolationsType.driving11Hours && driveViolationCounter > 0) {
      driveViolationCounter += 1;
    }
    if (item === ViolationsType.shift14hours && shiftViolationCounter === 0) {
      logViolations.push(item);
      shiftViolationCounter += 1;
    } else if (item === ViolationsType.shift14hours && shiftViolationCounter > 0) {
      shiftViolationCounter += 1;
    }

    if (item === ViolationsType.Cycle70Hours && cycleViolationCounter === 0) {
      logViolations.push(item);
      cycleViolationCounter += 1;
    } else if (item === ViolationsType.Cycle70Hours && cycleViolationCounter > 0) {
      cycleViolationCounter += 1;
    }
  });

  ptiVioltaionOccur?.forEach(item => {
    if (item === ViolationsPTIType.noPti && ptiCounter === 0) {
      ptiViolations.push(item);
      ptiCounter += 1;
    } else if (item === ViolationsPTIType.insufficientPtiTime && inSufficientViolationCounter === 0) {
      inSufficientViolationCounter += 1;
      ptiViolations.push(item);
    } else if (item === ViolationsPTIType.noPti) {
      ptiCounter += 1;
    } else if (item === ViolationsPTIType.insufficientPtiTime) {
      inSufficientViolationCounter += 1;
    }
  });

  return pti?.some(item => item?.type?.includes('1')) ||
    pti?.some(item => item?.type?.includes('3')) ||
    logViolations.length > 0 ? (
    <Row>
      <Col span={24}>
        <div className="Violations">
          <ul className="violationList">
            <li>
              <div className="listheading">
                <svgIcons.errorIcon />
                <span>Violations! </span>
              </div>
            </li>
            {ptiViolations?.map((items, index) => (
              <li key={`id_${index + 1}`}>
                <div className="violation">
                  <span style={{ marginRight: '10px' }}>
                    {items === ViolationsPTIType.noPti
                      ? 'No PTI'
                      : [items === ViolationsPTIType.insufficientPtiTime && 'PTI time is not sufficient']}
                  </span>
                  {items === ViolationsPTIType.noPti ? (
                    <Badge key={`id_${index + 1}`} count={ptiCounter > 1 ? ptiCounter : 0} />
                  ) : (
                    [
                      items === ViolationsPTIType.insufficientPtiTime && (
                        <Badge
                          key={`id_${index + 1}`}
                          count={inSufficientViolationCounter > 1 ? inSufficientViolationCounter : 0}
                        />
                      ),
                    ]
                  )}
                </div>
              </li>
            ))}
            {logViolations?.map((items, index) => (
              <li key={`id_${index + 1}`}>
                <div className="violation">
                  <span style={{ marginRight: '10px' }}>
                    {items === ViolationsType.Break30Mint
                      ? '30 Minute Break Required'
                      : [
                          items === ViolationsType.Cycle70Hours
                            ? '70 Hour Cycle Limit'
                            : [
                                items === ViolationsType.driving11Hours
                                  ? '11 Hour Driving Limit'
                                  : [
                                      items === ViolationsType.shift14hours
                                        ? '14 Hour Shift Limit'
                                        : [items === ViolationsType.other ? 'Other' : ''],
                                    ],
                              ],
                        ]}
                  </span>
                  {items === ViolationsType.Break30Mint ? (
                    <Badge key={`id_${index + 1}`} count={breakViolationCounter > 1 ? breakViolationCounter : 0} />
                  ) : (
                    [
                      items === ViolationsType.Cycle70Hours ? (
                        <Badge key={`id_${index + 1}`} count={cycleViolationCounter > 1 ? cycleViolationCounter : 0} />
                      ) : (
                        [
                          items === ViolationsType.driving11Hours ? (
                            <Badge
                              key={`id_${index + 1}`}
                              count={driveViolationCounter > 1 ? driveViolationCounter : 0}
                            />
                          ) : (
                            [
                              items === ViolationsType.shift14hours ? (
                                <Badge
                                  key={`id_${index + 1}`}
                                  count={shiftViolationCounter > 1 ? shiftViolationCounter : 0}
                                />
                              ) : (
                                [
                                  items === ViolationsType.other ? (
                                    <Badge
                                      key={`id_${index + 1}`}
                                      count={shiftViolationCounter > 1 ? shiftViolationCounter : 0}
                                    />
                                  ) : null,
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  ) : null;
};

export default React.memo(ViolationsGraph);
